$max-width: 840px;
$header_image-width: 200px;
$header_image-margin: 30px;
$layout-breakpoint-small: 900px;
$layout-breakpoint-verysmall: 700px;
$works-color: #006496;

// red #ac3c39 blue #0090d9 grey #6d7278 dark_grey #404246
$header-color: #4e585d;
$contact-color: #0090d9;
$footer-color: darken($contact-color,20%);

.container {
	max-width: $max-width;
	margin: auto;
	box-sizing: border-box;
	padding-left: 10px;
	padding-right: 10px;
}


.container_special {
 	@extend .container;
	padding-left: 25px;
	padding-right: 25px;
	@media (min-height: $max-width/0.9) {
		max-width: 90vh;
	}
	@media (max-width: $layout-breakpoint-small) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

.header {
	background-image: url(./imgs/bar_top.svg);
	background-size: 103%;
	background-position: right 50% bottom -1px;
	background-repeat: no-repeat;

	background-color: $header-color;

	padding: 16px 0 32px;
	text-align: center;
	color: #fff;

	font-size: 20px;
	line-height: 24px;

	p {
		margin: 0;
	}
}

p.header_service {
	font-size: 24px;
	font-weight: 800;
	margin: 10px 0;

	@media (max-width: $layout-breakpoint-small) {
		font-size: 20px;
	}
}


.works {
	background-color: #fff;
	padding: 10px 0 5px;
}
.works_like {
	font-size: 16px;
	color: #4e585d;
	text-align: center;
	margin: 0 0 18px;
}
.works_grid {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;


	@media (max-width: $layout-breakpoint-verysmall) {
		display: block;
	}
}
$work-radius: 15px;
.works_hovered {
	.work {
		opacity: 0.5;
	}
}
.work {
	width: 31%;
	margin-bottom: 3%;
	flex: 0 0 auto;
	transition: opacity 150ms linear;

	// @media (max-width: $layout-breakpoint-small) {
	// 	width: 48%;
	// 	&:nth-child(2) {
	// 		display: none;
	// 	}
	// }

	@media (max-width: $layout-breakpoint-verysmall) {
		width: auto;
		margin-bottom: 15px;
	}

	cursor: pointer;

	&:hover {
		opacity: 1;
		p {
			text-decoration: underline;
		}
		.work_block {
			border: 5px solid $works-color;
			border-radius:$work-radius + 4px;
			top: -4px;
			bottom: -4px;
			left: -4px;
			right: -4px;
		}
		.work_block_image {
			filter: saturate(120%);
		}
	}
}
.work_block_container {
	position: relative;
	transition: transform 150ms linear;
	padding-top: 30px;

	@media (max-width: $layout-breakpoint-small) {
		transform: none !important;
	}

	p {
		text-align: center;
		font-size: 16px;
		font-weight: bold;
		font-weight: 600;
		margin: 0;
		padding: 6px 2px 4px;
		background-color: $works-color;
		color: #fff;
		border-radius: $work-radius $work-radius 0 0;
		z-index: 2;

		position: absolute;
		top: 0;
		left: 0;
		right: 0;
	}
}
.work_block_ratiocontainer {
	padding-bottom: 56.25%;
	position: relative;
	transition: transform 150ms linear;

	@media (max-width: $layout-breakpoint-small) {
		transform: none !important;
	}
}
.work_block {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 2px solid $works-color;
	border-radius: $work-radius;
}
.work_block_image {
	filter: saturate(50%);

	@media (max-width: $layout-breakpoint-small) {
		filter: none !important;
	}
	transition: filter 150ms linear;
}
.work_block_video {
}
.work_block_image,.work_block_video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	border-radius: 0 0 $work-radius $work-radius;
}
.work_block_inner {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 2px solid #fff;
	border-radius: $work-radius - 1px;
	overflow: hidden;
}
.work_block_glass {
	position: absolute;
	top: 70%;
	bottom: -50%;
	left: -40%;
	right: -40%;
	background: rgba(255,255,255,0.3);
	background: linear-gradient(0deg, rgba(255,255,255,0.15) 0%, rgba(255,255,255,0.35) 100%);
	transition: transform 150ms linear,opacity 300ms linear;
}
.work_block_triangle {
	position: absolute;
	bottom: -1px;
	right: -1px;
	background-image: url(./imgs/triangle.svg);
	background-repeat: no-repeat;
	background-position: 100% 100%;
	height: 70px;
	width: 70px;
	border-radius: 0 0 $work-radius 0;
}



$leftright: 8px;
@keyframes leftright {
	0% { transform: translate(0px,0px) rotate(0.0001deg); }
	8% { transform: translate(-10px,0px) rotate(0.0001deg); }
	15% { transform: translate(0px,0px) rotate(0.0001deg); }
	100% { transform: translate(0px,0px) rotate(0.0001deg); }
}
.contact {
	background-image: url(./imgs/bar_bottom.svg);
	background-size: 103%;
	background-position: 50% -1px;
	background-repeat: no-repeat;
	background-color: $contact-color;

	padding: 32px 0;
	text-align: center;
	color: #fff;

	h1 {
		margin: 0;
		font-size: 30px;
		font-weight: 300;
		strong {
			font-weight: 600;
			white-space: nowrap;
		}
	}
	p {
		font-size: 16px;
		margin: 5px 0 25px;
		a {
			color: #fff;
		}
	}
	.contact_btn {
		margin-bottom: 10px;
		a {
			display: inline-block;
			background-color: rgba(255,255,255,0.1);
			border-radius: 12px;
			font-weight: bold;
			font-weight: 600;
			text-decoration: none;
			color: #fff;
			padding: 11px 15px;
			&:hover {
				background-color: rgba(255,255,255,0.2);
				text-decoration: underline;
			}
			position: relative;
			span {
				background-image: url(./imgs/hand.svg);
				width: 54px;
				height: 33px;
				display: block;
				position: absolute;
				top: 16px;
				left: -47px;
				animation: leftright 6s ease infinite;

				@media (max-width: $layout-breakpoint-small) {
					display: none;
				}
			}
		}
	}
}
.header,.contact {

	img {
		display: block;
		margin: 34px auto;
		max-width: 100%;
		height: auto;
	}
	a > img {
		border-radius: 8px;
		box-shadow: 0px 3px 8px rgba(0,0,0,0.2);
	}
}
body {
	// heat
	background-color: $footer-color;
}
.footer {
	background-color: $footer-color;
	color: rgba(255,255,255,0.5);
	font-size: 13px;
	padding: 15px 0;
	.footer_parts {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@media (max-width: $layout-breakpoint-small) {
			flex-direction: column-reverse;
			text-align: center;
			ul+ul {
				margin-bottom: 10px;
			}
		}
	}
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			display: inline-block;
			white-space: nowrap;
		}
		li+li {
			margin-left: 10px;
		}
		a {
			color: rgba(255,255,255,0.5);;
			img {
				opacity: 0.7;
				position: relative;
				top: 1px;
			}
			&:hover {
				color: #fff;
				img {
					opacity: 1;
				}
			}
		}
	}
}
