


.fade {
	transition: opacity 150ms linear;
	opacity: 0;

	&.entering,&.entered {
		opacity: 1;
	}
	&.exiting,&.exited {
		opacity: 0;
	}
}
